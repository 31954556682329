@import "../../../assets/styles/variables.scss";
.banner_wrapper {
    &.deposit_wrapper {
        &.deposit_wrapper_bg {
            background: url('../../../assets/images/digital-wallets-bg.jpg') center;
            background-size: cover;
            margin-bottom: 100px;
            h1,p {
                color: #fff;
            }
            @media (max-width: $breakpoint_tablet) {
                margin-bottom: 60px;
            }
        }
        &.contact_bg {
            background: url('../../../assets/images/contact_bg.jpg') center fixed;
            background-size: cover;
            margin-bottom: 100px;
            h1,p {
                color: #fff;
            }
            @media (max-width: $breakpoint_tablet) {
                margin-bottom: 100px;
            }
        }
        &.soon_wrap { height: 100vh;}
        &:before,&:after {
            display: none;
        }
        h1 {
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
            color: rgb(0,54,184);
            img {margin-top: 3px;}
        }
        &.cross_banner {
            position: relative;
            margin-bottom: 100px;
            @media (max-width: $breakpoint_tablet) {
                margin-bottom: 60px;
            }
            video {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            section {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            h1 {
                margin-bottom: 40px;
                color: #fff;
            }
            h2 {
                font-weight: 600;
                font-size: 26px;
                margin-bottom: 40px;
                color: #fff;
            }
            p {color: #fff;}
            .header_btn {
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }
    }
}