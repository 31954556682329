@import "../../../assets/styles/variables.scss";
.choose_wrapper {
    padding: 90px 0 96px;
    @media (max-width: $breakpoint_tablet) {
        padding: 60px 0;
    }
    .title_wrap {
        max-width: 100%;
    }
    ul {
        @media (max-width: $breakpoint_tablet) {
            gap: 30px
        }
        @media (max-width: $breakpoint_mobile_landscape) {
            grid-template-columns: 1fr;
        }
        li {
            section {
                padding: 34px 28px;
                border-radius: 10px;
                background-color: rgb(36,91,226);
                height: 100%;
                &:hover {
                    figure {
                        transform: scaleX(-1);
                    }
                }
                figure {
                    margin: 0 0 25px;
                    transition: all 0.4s ease-in-out;
                    width: 59px;
                    height: 59px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFFFFF;
                    box-shadow: 0 1px 2px rgba(18,55,105,0.16);
                    border-radius: 7px;
                }
                h3 {
                    font-weight: bold;
                    margin-bottom: 20px;
                    line-height: 1.07;
                    color: #fff;
                    font-size: 23px;
                }
                p {
                    color: #fff;
                    font-size: 19px;
                    margin-bottom: 0;
                }
            }
        }
    }
}