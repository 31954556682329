@import "../../../assets/styles/variables.scss";
.deposite_serve_wrapper {
    .deposite_serve_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 58px;
        margin-bottom: 90px;
        @media (max-width: $breakpoint_tablet) {
            flex-direction: column !important;
            gap: 30px;
            margin-bottom: 60px;
        }
        @media (max-width: $breakpoint_mobile_landscape) {
            flex-direction: column-reverse !important;
        }
        &:nth-child(2) {
            flex-direction: row-reverse;
        }
        figure {
            margin: 0;
            @media (max-width: $breakpoint_tablet) {
                img { max-width: 100%;}
            }
        }
        section {
            max-width: 552px;
            h2 {
                font-size: 35px;
                font-weight: bold;
                margin-bottom: 0;
                color: rgb(0,54,184);
                @media (max-width: $breakpoint_tablet) {
                    font-size: 24px;
                }
            }
            h3 {
                margin: 15px 0 0;
                font-size: 19px;
                font-weight: 600;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 16px;
                    margin-top: 15px;
                }
            }
            p {
                margin-bottom: 0;
                max-width: 85%;
                font-size: 19px;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 15px;
                    line-height: 24px;
                }
                &.first {
                    padding-top: 20px;
                    margin-top: 20px;
                    border-top: 1px solid rgba(20,20,20,0.1);
                }
            }
            ul {
                margin: 26px 0;
                li {
                    font-weight: 500;
                    position: relative;
                    padding-left: 44px;
                    letter-spacing: -0.5px;
                    margin: 8px 0;
                    font-size: 19px;
                    @media (max-width: $breakpoint_tablet) {
                        font-size: 15px;
                        line-height: 24px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: url('../../../assets/images/check.svg') no-repeat;
                        width: 28px;
                        height: 28px;
                        background-size: contain;
                    }
                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}