@import "../../../assets/styles/variables.scss";
.start_saving_wrap {
    text-align: center;
    margin-bottom: 100px;
    @media (max-width: $breakpoint_tablet) {
        margin-bottom: 60px;
    }
    .start_saving {
        background: linear-gradient(313deg, rgba(1,56,183,0) 0%, rgba(1,56,183,1) 100%);
        padding: 2px;
        border-radius: 16px;
        overflow: hidden;
        section {
            padding: 117px 0;
            background: #fff url('../../../assets/images/box-shape.png');
            border-radius: 16px;
            @media (max-width: $breakpoint_tablet) {
                padding: 60px 30px;
            }
        }
    }
    h2 {
        color: rgb(0, 54, 184);
        font-weight: 800;
        margin-bottom: 20px;
        font-size: 42px;
        @media (max-width: $breakpoint_tablet) {
            font-size: 28px;
        }
    }
    h3 {
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: 600;
        color: #0036b8;
        @media (max-width: $breakpoint_tablet) {
            font-size: 22px;
        }
    }
    p {
        max-width: 870px;
        font-size: 21px;
        line-height: 28px;
        margin: 0 auto 28px;
        @media (max-width: $breakpoint_tablet) {
            font-size: 15px;
            line-height: 24px;
        }
    }
}