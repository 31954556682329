@import "../../assets/styles/variables.scss";
.header_wrap {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    border-bottom: 1px solid rgba(32,30,31,0.5);
    padding: 20px 15px;
    background-color: #fff;
    z-index: 999;
    &.desktop_wrap {
        & > .container {
            @media (max-width: $breakpoint_tablet) {
                display: none;
            }
        }
    }
    &.header_wrap_mobile {
        display: none;
        @media (max-width: $breakpoint_tablet) {
            display: block;
        }
    }
    .navigation_wrap {
        margin: 0 0 0 auto;
        @media (max-width: $breakpoint_tablet) {
            background-color: #F9F9FD;
            display: none;
            position: absolute;
            right: 0;
            top: 55px;
            width: 100%;
            padding: 50px;
            &.active {
                display: block;
            }
        }
        ul {
            gap: 35px;
            @media (max-width: $breakpoint_tablet) {
                gap: 30px;
                align-items: flex-start;
            }
            li {
                position: relative;
                a {
                    line-height: 24px;
                    transition: all 0.4s ease-in-out;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 18px;
                    color: #0036b8;
                    font-weight: 600;
                    &.open_drop {
                        img { transform: scale(-1);}
                    }
                    &.active {
                        color: #e5b23d !important;
                    }
                    &:hover {
                        color: #e5b23d;
                    }
                }
            }
        }
    }
    .navigation_right {
        margin-left: 35px;
        ul {
            gap: 24px;
        }
    }
}
.header_btn {
    background: radial-gradient(circle, rgba(3,63,204,1) 0%, rgba(36,176,233,1) 100%);
    line-height: 16px;
    padding: 16px 32px;
    color: #fff !important;
    font-size: 18px;
    border-radius: 50px;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    border: none;
    outline: none !important;
    line-height: 1.2;
    &:hover {
        box-shadow: 0 10px 50px rgba(36,176,233,0.2);
    }
}
.header_wrap .navigation_wrap ul li a.header_btn.active {
    color: #fff !important;
}
.menu_btn {
    display: none;
    margin-left: auto;
    width: 36px;
    height: 20px;
    position: relative;
    border: none;
    margin-right: 7px;
    background: none;
    &.active {
        span {
            background: transparent;
            &::before {
                width: 100%;
                transform: rotate(45deg);
                top: 10px;
            }
            &::after {
                width: 100%;
                transform: rotate(-45deg);
                top: 10px;
            }
        }
    }
    span {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 2px;
        background: #000;
        transition: all 0.4s ease-in-out;
        &::before {
            content: '';
            position: absolute;
            width: calc(100% - 6px);
            height: 2px;
            top: 9px;
            right: 0;
            background-color: #000;
            transition: all 0.4s ease-in-out;
        }
        &::after {
            content: '';
            position: absolute;
            width: calc(100% - 13px);
            height: 2px;
            top: 18px;
            right: 0;
            background-color: #000;
            transition: all 0.4s ease-in-out;
        }
    }
    @media (max-width: $breakpoint_tablet) {
        display: block;
        position: absolute;
        right: 10px;
        top: 7px;
    }
}
.navigation_drop {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 9;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    width: 230px;
    border-radius: 5px;
    transform: translateY(30px);
    @media (max-width: $breakpoint_tablet) {
        position: relative;
        background: transparent;
        padding: 0;
        box-shadow: none;
        width: auto;
        transform: none;
        top: 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 30px;
    }
    a {
        display: block;
        padding: 10px 0;
        &::before { display: none;}
        &.active,&:hover { color: #9B6F1E;}
        @media (max-width: $breakpoint_tablet) {
            padding: 0;
        }
    }
}