@import "../../../assets/styles/variables.scss";
.digital_wrapper {
    padding: 120px 0 0;
    @media (max-width: $breakpoint_tablet) {
        padding: 60px 0 0;
    }
    &.digital_boxes {
        padding-top: 0;
        ul li:last-child {
            .digital_content {
                @media (max-width: $breakpoint_mobile_landscape) {
                    background: linear-gradient(180deg, rgb(192, 222, 247) 0%, rgb(1, 56, 183) 52%, rgb(1, 56, 183) 68%) !important;
                }
            }
        }
        .digital_content {
            background: url('../../../assets/images/shape5.png') center !important;
            background-size: 100% 100% !important;
            min-height: 418px;
            align-items: center;
            @media (max-width: $breakpoint_tablet) {
                padding-bottom: 0 !important;
                min-height: 300px;
                & > img { max-width: 100%;}
            }
            section {
                p {
                    text-overflow: unset;
                    overflow: unset;
                    -webkit-line-clamp: unset;
                    -webkit-box-orient: unset;
                    margin-bottom: 0;
                }
            }
        }
    }
    ul {
        li {
            margin-bottom: 50px;
            @media (max-width: $breakpoint_tablet) {
                margin-bottom: 30px;
            }
            &:nth-child(2) {
                .digital_content {
                    background: url('../../../assets/images/shape2.png') center;
                    background-size: 100% 100%;
                    @media (max-width: $breakpoint_mobile_landscape) {
                        background: linear-gradient(0deg, rgba(255,228,143,1) 0%, rgba(254,209,67,1) 36%, rgba(254,209,67,1) 81%) !important;
                    }
                }
            }
            &:nth-child(3) {
                .digital_content {
                    background: url('../../../assets/images/shape3.png') center;
                    background-size: 100% 100%;
                }
            }
            &:last-child {
                margin-bottom: 0;
                .digital_content {
                    background: url('../../../assets/images/shape4.png') center;
                    background-size: 100% 100%;
                    padding-bottom: 30px;
                    @media (max-width: $breakpoint_mobile_landscape) {
                        background: linear-gradient(0deg, rgba(255,228,143,1) 0%, rgba(254,209,67,1) 36%, rgba(254,209,67,1) 81%) !important;
                    }
                }
            }
            &:nth-child(2n) {
                .digital_content {
                    justify-content: flex-end;
                    section {
                        h3,h4,p {
                            color: #000000;
                        }
                    }
                }
            }
            .digital_content {
                padding: 73px 113px;
                background: url('../../../assets/images/shape1.png') center;
                background-size: 100% 100%;
                display: flex;
                @media (max-width: $breakpoint_tablet) {
                    padding: 40px 70px 40px;
                }
                @media (max-width: $breakpoint_mobile_landscape) {
                    background: linear-gradient(180deg, rgba(192,222,247,1) 0%, rgba(1,56,183,1) 52%, rgba(1,56,183,1) 68%) !important;
                    border-radius: 16px;
                    padding: 30px 30px 0;
                    flex-direction: column;
                }
                & > img {
                    display: none;
                    @media (max-width: $breakpoint_mobile_landscape) {
                        display: block;
                        margin-top: 30px;
                    }
                }
                section {
                    max-width: 620px;
                    @media (max-width: $breakpoint_tablet) {
                        max-width: 400px;
                    }
                    @media (max-width: $breakpoint_mobile_landscape) {
                        max-width: 100%;
                    }
                    h3 {
                        font-size: 40px;
                        font-weight: 800;
                        margin-bottom: 25px;
                        line-height: 1;
                        color: #fff;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 26px;
                            margin-bottom: 20px;
                        }
                    }
                    h4 {
                        font-size: 26px;
                        margin-bottom: 25px;
                        font-weight: 600;
                        color: #fff;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 16px;
                            margin-bottom: 20px;
                        }
                    }
                    p {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 28px;
                        color: #fff;
                        font-size: 21px;
                        line-height: 28px;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 15px;
                            line-height: 24px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}
.digital_min_boxes {
    margin-bottom: 100px;
    @media (max-width: $breakpoint_tablet) {
        margin-bottom: 60px;
    }
    .container {
        display: flex;
        @media (max-width: $breakpoint_mobile_landscape) {
            flex-direction: column;
        }
    }
    .mini_content {
        margin-top: 20px;
        width: 50%;
        background: url('../../../assets/images/mini-shape1.png') center;
        background-size: 100% 100%;
        padding: 83px 50px;
        @media (max-width: $breakpoint_tablet) {
            padding: 40px;
        }
        @media (max-width: $breakpoint_mobile_landscape) {
            width: 100%;
        }
        &:last-child {
            background: url('../../../assets/images/mini-shape2.png') center;
            background-size: 100% 100%;
        }
        h2 {
            font-size: 36px;
            font-weight: 800;
            margin-bottom: 35px;
            color: #fff;
            @media (max-width: $breakpoint_tablet) {
                font-size: 25px;
                margin-bottom: 25px;
            }
        }
        p {
            margin-bottom: 0;
            font-size: 20px;
            color: #fff;
            @media (max-width: $breakpoint_tablet) {
                font-size: 15px;
                line-height: 22px;
            }
        }
    }
}