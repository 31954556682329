$white_color: #fff;
$dark_color: #19191D;
$body_color: #06141D;
$green_color: #48C989;
$gray_bg: #e5e5e5;
$title_bg_color: #ccf1e5;
$error_color: #ff3e3e;
$yellow_color: #eef300;
$red_color: #ff0000;
$w_100: 100%;
$position_rel: relative;
$position_abs: absolute;
$hidden: hidden;
$transparent: transparent;
$flex: flex;
$margin_auto: 0 auto;
$breakpoint_mobile_landscape: 767px;
$breakpoint_mobile_portrait: 600px;
$breakpoint_small_portrait: 380px;
$breakpoint_tablet: 1100px;
$breakpoint_mini_laptop: 1300px;
$breakpoint_laptop: 1500px;
$d_none: none;
$d_block: block;
