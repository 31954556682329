@import "../../../assets/styles/variables.scss";
.loan_options_wrap {
    .loan_heading {
        display: flex;
        margin-bottom: 60px;
        align-items: center;
        gap: 90px;
        justify-content: space-between;
        @media (max-width: $breakpoint_tablet) {
            flex-direction: column;
            gap: 0;
            align-items: flex-start;
            margin-bottom: 30px;
            &.rev {
                flex-direction: column-reverse;
            }
        }
        section {
            h2 {
                margin: 0 0 30px;
                font-size: 44px;
                line-height: 1.2;
                color: #0036b8;
                font-weight: bold;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 32px;
                    margin-bottom: 20px;
                    br {
                        display: none;
                    }
                }
            }
            h3 {
                margin: 0;
                white-space: nowrap;
                font-weight: bold;
                font-size: 20px;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 17px;
                    margin: 0 0 20px;
                }
            }
        }
        p {
            margin: 0;
            font-size: 20px;
            max-width: 736px;
            @media (max-width: $breakpoint_tablet) {
                font-size: 15px;
                line-height: 22px;
            }
        }
    }
    .loan_option_content {
        padding: 23px;
        border: 1px solid rgba(20,20,20,0.1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 100px;
        @media (max-width: $breakpoint_tablet) {
            margin-bottom: 60px;
            gap: 30px;
        }
        @media (max-width: $breakpoint_mobile_landscape) {
            flex-direction: column-reverse;
            &.rev {
                flex-direction: column;
            }
        }
        img {
            max-width: 612px;
            border-radius: 8px;
            border: 1px solid rgba(20,20,20,0.1);
            @media (max-width: $breakpoint_tablet) {
                max-width: 400px;
            }
            @media (max-width: $breakpoint_mobile_landscape) {
                max-width: 100%;
            }
        }
        ul {
            li {
                font-size: 21px;
                color: #141414;
                font-weight: 500;
                position: relative;
                padding-left: 44px;
                letter-spacing: -0.5px;
                margin: 16px 0;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 15px;
                    line-height: 24px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url('../../../assets/images/check.svg') no-repeat;
                    width: 28px;
                    height: 28px;
                    background-size: contain;
                }
                span {
                    font-weight: 600;
                }
            }
        }
    }
}

