@import "../../assets/styles/variables.scss";
.footer_wrap {
    padding: 100px 0 0;
    background-color: #245be5;
    @media (max-width: $breakpoint_tablet) {
        padding: 60px 0 0;
    }
    .footer_info_wrapper {
        margin-bottom: 93px;
        @media (max-width: $breakpoint_tablet) {
            flex-direction: column;
            margin-bottom: 60px;
        }
    }
    .footer_info {
        max-width: 345px;
        @media (max-width: $breakpoint_tablet) {
            text-align: center;
            margin: 0 auto;
        }
        & > a {
            display: inline-block;
        }
        p {
            margin: 25px 0;
            color: rgba(20,20,20,0.5);
            font-size: 18px;
            max-width: 95%;
            @media (max-width: $breakpoint_tablet) {
                max-width: 100%;
            }
        }
        ul {
            gap: 24px;
            @media (max-width: $breakpoint_tablet) {
                justify-content: center;
            }
            li {
                a {
                    display: inline-block;
                    transition: all 0.4s ease-in-out;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
    .contact_info {
        margin-left: auto;
        @media (max-width: $breakpoint_tablet) {
            margin-right: auto;
            margin-top: 30px;
        }
        ul {
            gap: 32px;
            @media (max-width: $breakpoint_tablet) {
                gap: 30px;
            }
            @media (max-width: $breakpoint_mobile_landscape) {
                flex-direction: column;
            }
            li {
                a {
                    display: inline-block;
                    background-color: #245BE2;
                    padding: 32px;
                    max-width: 304px;
                    border-radius: 16px;
                    transition: all 0.4s ease-in-out;
                    box-shadow: 0 10px 50px rgba(64,72,82,0.1);
                    &:hover { transform: scale(0.98);}
                    img { margin-bottom: 24px;}
                    h3 {
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 12px;
                        color: #fff;
                    }
                    p { line-height: 26px; color: #D4D4D4; margin: 0 0 12px;}
                    h4 {
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 0;
                        color: #fff;
                    }
                }
            }
        }
    }
    .new_footer {
        display: flex;
        margin: 0 -12px 30px;
        @media (max-width: $breakpoint_mobile_landscape) {
            flex-wrap: wrap;
            justify-content: center;
        }
        h4 {
            font-size: 24px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 22px;
        }
        .logo_info {
            width: 25%;
            padding: 0 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            @media (max-width: $breakpoint_mobile_landscape) {
                width: 100%;
                margin-bottom: 50px;
            }
            & > a {
                margin-bottom: 16px; display: inline-block;
                @media (max-width: $breakpoint_tablet) {
                    img {
                        max-width: 100%;
                    }
                }
            }
            ul {
                justify-content: center;
                gap: 20px;
                li {
                    a {
                        display: inline-block;
                        transition: all 0.2s ease-in-out;
                        &:hover {
                            transform: scale(0.9);
                        }
                        img {
                            max-width: 40px;
                        }
                    }
                }
            }
        }
        .link_info {
            width: 25%;
            padding: 0 12px;
            @media (max-width: $breakpoint_mobile_landscape) {
                width: 50%;
                text-align: center;
                margin-bottom: 20px;
            }
            ul {
                li {
                    a {
                        color: #fff;
                        margin-bottom: 10px;
                        display: inline-block;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .contact_information {
            width: 25%;
            padding: 0 12px;
            @media (max-width: $breakpoint_mobile_landscape) {
                width: 52%;
                text-align: left;
                h4 {
                    text-align: center;
                }
            }
            ul {
                li {
                    margin-bottom: 10px;
                    span,a {
                        display: flex;
                        color: #fff;
                        align-items: center;
                        gap: 5px;
                        @media (max-width: $breakpoint_mobile_landscape) {
                            justify-content: flex-start;
                        }
                        i {
                            line-height: 1;
                            flex: 0 0 20px;
                            width: 20px;
                        }
                    }
                    span {
                        line-height: 21px;
                    }
                    a {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .copyright_wrap {
        border-top: 1px solid #868ea5;
        padding: 30px 0 37px;
        @media (max-width: $breakpoint_tablet) {
            padding: 20px;
            text-align: center;
        }
        p {
            margin: 0;
            color: #fff;
            font-weight: 500;
        }
    }
}