@import "../../../assets/styles/variables.scss";
.values_wrapper {
    &.story_wrap {
        margin-top: 150px;
        margin-bottom: 100px;
        @media (max-width: $breakpoint_tablet) {
            margin: 150px 0 60px;
        }
    }
    &.values_wrapper2 {
        padding: 100px 0;
        background-color: #343098;
        background-image: url(../../../assets/images/value_bg.png);
        background-position: 0,right bottom 50px;
        background-repeat: no-repeat;
        margin-bottom: 100px;
        @media (max-width: $breakpoint_tablet) {
            padding: 60px 0;
            margin-bottom: 60px;
        }
        & > .container { z-index: 2;}
        h2 { color: #fff;}
    }
    h2 {
        font-size: 60px;
        font-weight: 800;
        color: #0036b8;
        line-height: 0.8;
        text-align: center;
        margin-bottom: 60px;
        @media (max-width: $breakpoint_tablet) {
            font-size: 30px;
            margin-bottom: 40px;
        }
    }
    ul {
        &.grid-container-2 {
            gap: 38px;
            @media (max-width: $breakpoint_tablet) {
                gap: 30px;
            }
            @media (max-width: $breakpoint_mobile_landscape) {
                grid-template-columns: 1fr;
            }
        }
        li {
            section {
                height: 323px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 47px 35px 47px 38px;
                border-radius: 10px;
                // background-color: rgba(250,250,250,1);
                background: linear-gradient(180deg, rgba(250,250,250,0.8) 0%, rgba(250,250,250,1) 60%);
                position: relative;
                &:hover {
                    &::before { opacity: 1;}
                    img {
                        transform: scaleX(-1);
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: url('../../../assets/images/value1.jpg');
                    border-radius: 12px;
                    z-index: -1;
                    opacity: 0;
                    transition: all 0.4s ease-in-out;
                    @media (max-width: $breakpoint_tablet) {
                        opacity: 1;
                    }
                }
                img {
                    max-width: 60px;
                    margin-bottom: 10px;
                    display: inline-block;
                    transition: all 0.2s ease-in-out;
                }
                h3 {
                    font-weight: bold;
                    font-size: 38px;
                    margin-bottom: 25px;
                    color: #1E1E1E;
                    @media (max-width: $breakpoint_tablet) {
                        font-size: 28px;
                        margin-bottom: 20px;
                    }
                }
                p {
                    margin-bottom: 0;
                    font-size: 19px;
                    line-height: 30px;
                    color: #1E1E1E;
                    @media (max-width: $breakpoint_tablet) {
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
.why_azmara_wrap {
    background: url('../../../assets/images/why_bg.png') no-repeat right bottom;
    position: relative;
    padding: 0 0 100px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url('../../../assets/images/logoshape.png') no-repeat center;
        background-size: 500px;
        pointer-events: none;
    }
    @media (max-width: $breakpoint_tablet) {
        background: none;
        padding-bottom: 60px;
    }
    .container {
        & > p {
            text-align: center;
            max-width: 1100px;
            font-size: 21px;
            margin: 0 auto 60px;
            @media (max-width: $breakpoint_tablet) {
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 30px;
            }
        }
        & > h2 {
            margin-bottom: 20px;
        }
    }
}
.story_wrapper {
    display: flex;
    gap: 50px;
    align-items: center;
    @media (max-width: $breakpoint_tablet) {
        flex-direction: column;
        gap: 0;
    }
    section {
        max-width: 50%;
        @media (max-width: $breakpoint_tablet) {
            max-width: 100%;
        }
        ul {
            & > li {
                position: relative;
                padding-left: 40px;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: -2px;
                    background: url('../../../assets/images/bluecheck.svg') no-repeat center;
                    background-size: contain;
                    width: 25px;
                    height: 25px;
                }
            }
            li {
                font-size: 21px;
                margin-bottom: 20px;
                line-height: 30px;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 15px;
                    line-height: 24px;
                }
                ol { margin-top: 20px;}
            }
        }
        h2 {
            text-align: left;
            margin-bottom: 50px;
            font-size: 44px;
            line-height: 1.2;
            @media (max-width: $breakpoint_tablet) {
                font-size: 30px;
                margin-bottom: 30px;
                line-height: 1.3;
            }
        }
        p {
            font-size: 21px;
            margin-bottom: 30px;
            line-height: 30px;
            a {
                color: #0036b8;
                &:hover { text-decoration: underline;}
            }
            @media (max-width: $breakpoint_tablet) {
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 20px;
            }
        }
    }
    figure {
        margin: 0;
        max-width: 50%;
        img { max-width: 100%;}
    }
}