@import "../../../assets/styles/variables.scss";
.loan_chose_wrap {
    background: linear-gradient(0deg, rgba(1,56,183,1) 0%, rgba(52,182,235,1) 100%);
    padding: 80px 0;
    position: relative;
    margin-bottom: 100px;
    @media (max-width: $breakpoint_tablet) {
        margin-bottom: 60px;
        padding: 60px 0;
    }
    .container {
        @media (max-width: $breakpoint_mobile_landscape) {
            flex-direction: column;
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url('../../../assets/images/chose-shape.svg') center;
        background-size: cover;
        pointer-events: none;
    }
    img {
        max-width: 549px;
        margin-left: auto;
        border-radius: 20px;
        @media (max-width: $breakpoint_tablet) {
            max-width: 300px;
        }
        @media (max-width: $breakpoint_mobile_landscape) {
            max-width: 100%;
        }
    }
    section {
        @media (max-width: $breakpoint_tablet) {
            margin-bottom: 30px;
        }
        h2 {
            font-size: 46px;
            margin: 0 0 40px;
            font-weight: 800;
            color: #fff;
            @media (max-width: $breakpoint_tablet) {
                font-size: 32px;
                margin-bottom: 20px;
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            li {
                font-size: 21px;
                color: #141414;
                font-weight: 500;
                position: relative;
                padding-left: 44px;
                letter-spacing: -0.5px;
                margin: 16px 0;
                color: #fff;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 17px;
                    line-height: 24px;
                    margin: 8px 0;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url('../../../assets/images/white-arrow.svg') no-repeat;
                    width: 28px;
                    height: 28px;
                    background-size: contain;
                    @media (max-width: $breakpoint_tablet) {
                        width: 24px;
                        height: 24px;
                    }
                }
                span {
                    font-weight: 600;
                }
            }
        }
    }
}