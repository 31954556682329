@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  font: 400 17px/26px "Jost", sans-serif;
  overflow-x: clip;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #757171;
  zoom: 0.9;
  top: 0 !important;
}
p { margin: 0 0 10px;}
#layout_wrapper {
  overflow: hidden;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  line-height: 1.17;
  margin: 0 0 10px;
  color: #201E1F;
  font-weight: 400;
  font-family: "Jost", sans-serif;
}
.img-responsive {
  width: 100%;
  height: auto;
  display: block;
}
.pre-word-line {
  white-space: pre-line;
}
.list-none {
  margin: 0;
  padding: 0;
  list-style: none;
}
.word-break {
  word-break: break-word;
}
.d-block {
  display: block;
}
.no-padding {
  padding: 0 !important;
}
.is-scrollable {
  overflow: hidden;
}
input[type="submit"],
button {
  cursor: pointer;
}
img {
  vertical-align: top;
  border-style: none;
}
*:focus {
  outline: none;
}
.transition {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}
.w-100 {
  width: 100% !important;
}
.error-msg {
  color: red;
  margin-top: 15px;
  display: block;
}
.error-msg:first-letter {
  text-transform: capitalize;
}
#wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.container {
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  width: 100%;
}
.container.fluid {
  width: 100%;
  max-width: none;
}
.container:after {
  display: block;
  clear: both;
  content: "";
}
#main {
  width: 100%;
  min-height: calc(100vh - 110px);
  padding: 0 0 50px;
}
.required-field {
  color: #db0000;
  font-style: normal;
  margin-left: 5px;
  font-size: 17px;
}
a {
  text-decoration: none;
  color: $dark_color;
}
.pointer {
  cursor: pointer;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.direction-column {
  flex-direction: column;
}
.direction-row {
  flex-direction: row;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.items-center {
  align-items: center;
}
.text-center {
  text-align: center;
  position: relative;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.flex-text-center {
  justify-content: center;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.m-0 {
  margin: 0;
}
.btn-faded {
  opacity: 0.5;
}
.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
input,
button,
textarea {
  font: 400 16px/20px "Geologica", sans-serif;
}
input,
textarea,
select {
  width: 100%;
  box-shadow: 4px 4px 20px rgba(0,0,0,0.08);
  border-radius: 4px;
  border: 1px solid #C4C4C4;
  background-color: #fff;
  height: 50px;
  padding-left: 20px;
  padding-right: 30px;
  color: #201E1F;
  font-size: 15px;
  font-family: "Jost", sans-serif;
  &::placeholder { color: #201E1F;}
}
textarea {
  height: 188px;
  padding-top: 20px;
  @media (max-width: $breakpoint_laptop) {
    height: 120px;
  }
}
button:focus,
textarea:focus,
input:focus,
select,
textarea {
  outline: none;
}
textarea::placeholder,
input::placeholder {
  color: #201E1F;
}
textarea,
input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="string"],
select {
  color: #201E1F;
}
input[type="date"] {
  text-transform: uppercase;
  background: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
select {
  outline: none;
}
.table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
.btn-green {
  color: white;
  background-color: #6bb64a;
  border-radius: 10px;
  border: 1px solid #6bb64a;
  padding: 10px 22px;
  font-size: 15px;
  @media (max-width: $breakpoint_laptop) {
    padding: 7px 20px;
    font-size: 13px;
  }
  &:hover {
    background-color: white;
    color: #6bb64a;
    border: 1px solid #6bb64a;
  }
}
.contact_margin {
  margin-right: 18px;
}
.grid-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.grid-container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 29px;
}
.grid-container-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: $breakpoint_tablet) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $breakpoint_mobile_portrait) {
    grid-template-columns: 1fr;
  }
}
.grid-container-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: $breakpoint_tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: $breakpoint_mobile_portrait) {
    grid-template-columns: 1fr;
  }
}

.globle_wrapper {
  overflow: hidden;
  position: relative;
}
.simple_btn {
  display: inline-block;
  color: #0848BD;
  font-weight: 600;
  border-radius: 55px;
  padding: 12px 32px;
  border: none;
  line-height: 24px;
  background-color: #fff;
  box-shadow: inset 3px 6px 7px rgba(255, 255, 255, 0.23);
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: scale(0.97);
  }
  &.black {
    background-color: #000000;
    color: #FCFCFC;
  }
}
.map_wrap {
  padding: 125px 0 0;
  @media (max-width: $breakpoint_tablet) {
    padding: 60px 0 0;
  }
  img {
    width: 100%;
    height: auto;
    @media (max-width: $breakpoint_mobile_landscape) {
      width: 700px;
      margin-left: -56px;
    }
  }
}
#root { overflow: hidden;}
#google_translate_element > div {
  height: 44px;
  overflow: hidden;
  & ~ div { display: none}
  & > div {
    margin: 0;
    select {
      display: block;
      padding: 0 8px 0 0;
      border: none;
      height: 44px;
      border-radius: 5px;
      margin: 0;
      & ~ select {
        display: none !important;
      }
    }
  }
  &  > span {
    display: none
  }
}
.flag-drop {
  align-items: center;
  border: 1px solid #4743c9;
  border-radius: 5px;
  position: relative;
  margin-left: 5px;
  padding-left: 8px;
  display: flex;
  img {
    max-width: 30px;
    margin-right: 5px;
    pointer-events: none;
  }
}
body > .skiptranslate, #goog-gt-tt,#gtx-trans { display: none !important;}
font {
  background-color: transparent !important;
  box-shadow: none !important;
}
