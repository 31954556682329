@import "../../../assets/styles/variables.scss";
.serices_wrapper {
    padding: 94px 0 0;
    @media (max-width: $breakpoint_tablet) {
        padding: 60px 0 0;
    }
    .services_list {
        ul {
            @media (max-width: $breakpoint_mobile_landscape) {
                grid-template-columns: 1fr;
            }
            li {
                &:first-child {
                    section {
                        &:before {
                            background: url('../../../assets/images/money.svg') no-repeat right bottom;
                            background-size: cover;
                            transform: scaleX(-1);
                        }
                    }
                }
                &:nth-child(2) {
                    section {
                        &:before {
                            background: url('../../../assets/images/bank.svg') no-repeat right bottom;
                            background-size: cover;
                        }
                    }
                }
                &:nth-child(3) {
                    section {
                        &:before {
                            background: url('../../../assets/images/auth.svg') no-repeat right bottom;
                            background-size: cover;
                        }
                    }
                }
                &:last-child {
                    section {
                        &:before {
                            background: url('../../../assets/images/transaction.svg') no-repeat right bottom;
                            background-size: cover;
                            transform: rotate(-37deg);
                            width: 100px;
                            height: 100px;
                            bottom: -6px;
                            right: -18px;
                        }
                    }
                }
                section {
                    background: rgb(36,91,226);
                    border-radius: 10px;
                    padding: 26px 38px 35px;
                    height: 100%;
                    position: relative;
                    overflow: hidden;
                    @media (max-width: $breakpoint_tablet) {
                        padding: 26px 30px 30px;
                    }
                    &:hover {
                        h3 {
                            img { transform: scaleX(-1);}
                        }
                    }
                    &::before {
                        content: "''";
                        position: absolute;
                        bottom: -55px;
                        right: -23px;
                        width: 162px;
                        height: 162px;
                        opacity: 0.2;
                        pointer-events: none;
                    }
                    h3 {
                        margin-bottom: 20px;
                        font-size: 24px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        gap: 25px;
                        text-transform: capitalize;
                        color: #fff;
                        img {
                            display: inline-block;
                            transition: all 0.4s ease-in-out;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        font-size: 19px;
                        line-height: 24px;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.title_wrap {
    margin: 0 auto 50px;
    text-align: center;
    max-width: 710px;
    @media (max-width: $breakpoint_tablet) {
        margin-bottom: 40px;
    }
    h2 {
        margin-bottom: 0;
        font-size: 42px;
        font-weight: 800;
        color: #0036b8;
        @media (max-width: $breakpoint_tablet) {
            font-size: 28px;
        }
    }
    p {
        margin: 20px auto 0;
        max-width: 662px;
        font-size: 21px;
        line-height: 28px;
        @media (max-width: $breakpoint_tablet) {
            font-size: 15px;
            line-height: 24px;
            margin-top: 20px;
        }
    }
}