@import "../../../assets/styles/variables.scss";
.why_azamra_wrap {
    margin-bottom: 55px;
    @media (max-width: $breakpoint_tablet) {
        margin-bottom: 30px;
    }
    .why_azamra_content {
        padding: 114px 64px 64px;
        background: #FAFAFA url('../../../assets/images/why1.jpg') right center no-repeat;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 323px;
        @media (max-width: $breakpoint_tablet) {
            padding: 50px 30px;
            min-height: auto;
            background-size: 450px 100% !important;
        }
        @media (max-width: $breakpoint_mobile_landscape) {
            background-image: none !important;
            padding: 50px 30px;
            min-height: auto;
            align-items: flex-start !important;
        }
        section {
            max-width: 653px;
            @media (max-width: $breakpoint_tablet) {
                max-width: 450px;
            }
            @media (max-width: $breakpoint_mobile_landscape) {
                max-width: 100%;
            }
            h2 {
                margin: 0 0 20px;
                font-size: 39px;
                font-weight: bold;
                color: #0036b8;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 27px;
                }
            }
            p {
                margin: 0;
                font-size: 19px;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 17px;
                }
            }
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        li {
            margin-bottom: 55px;
            @media (max-width: $breakpoint_tablet) {
                margin-bottom: 30px;
            }
            &:nth-child(2n) {
              .why_azamra_content {
                background: #FAFAFA url('../../../assets/images/why2.jpg') left center no-repeat;
                align-items: flex-end;
              }
            }
            &:nth-child(3n) {
                .why_azamra_content {
                  background: #FAFAFA url('../../../assets/images/why3.jpg') right center no-repeat;
                }
            }
            &:last-child {
                .why_azamra_content {
                  background: #FAFAFA url('../../../assets/images/why4.jpg') left center no-repeat;
                  align-items: flex-end;
                }
            }
        }
    }
}