@import "../../../assets/styles/variables.scss";
.banner_wrapper {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 89px;
    &.home_banner {
        text-align: left;
        section {
            max-width: 90%;
            @media (max-width: $breakpoint_tablet) {max-width: 100%;}
            h1, p {
                color: #fff;
                font-family: "Jost", sans-serif;
            }
            p {
                margin: 0 0 50px;
                @media (max-width: $breakpoint_tablet) {margin-bottom: 30px;}
            }
        }
        .banner_btns {
            justify-content: flex-start;
        }
    }
    .banner_slide {
        background: url('../../../assets/images/banner_bg3.png') center no-repeat;
        background-size: cover;
        min-height: 663px;
        display: flex;
        align-items: center;
        @media (max-width: $breakpoint_tablet) {
            min-height: 500px;
        }
        &.slide2 {
            background: url('../../../assets/images/banner_bg3.jpg') center no-repeat;
            background-size: cover;
        }
        &.slide3 {
            background: url('../../../assets/images/banner_bg2.jpg') center no-repeat;
            background-size: cover;
        }
    }
    @media (max-width: $breakpoint_tablet) {
        padding-top: 68px;
    }
    section {
        // border-bottom: 1px solid rgba(0,0,0,0.5);
        padding: 120px 0;
        @media (max-width: $breakpoint_tablet) {
            padding: 60px 0;
        }
    }
    // &::before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     bottom: -235px;
    //     width: 100%;
    //     height: 100%;
    //     background: url('../../../assets/images/left-shape.svg') left bottom no-repeat;
    //     background-size: contain;
    //     pointer-events: none;
    //     @media (max-width: $breakpoint_tablet) {
    //         display: none;
    //     }
    // }
    // &::after {
    //     content: "";
    //     position: absolute;
    //     right: 0;
    //     bottom: -235px;
    //     width: 100%;
    //     height: 100%;
    //     background: url('../../../assets/images/right-shape.svg') right bottom no-repeat;
    //     background-size: contain;
    //     pointer-events: none;
    //     @media (max-width: $breakpoint_tablet) {
    //         display: none;
    //     }
    // }
    h1 {
        margin: 0 0 50px;
        font-size: 60px;
        font-weight: 800;
        @media (max-width: $breakpoint_tablet) {
            font-size: 30px;
            margin-bottom: 30px;
            img {
                max-width: 32px;
            }
        }
    }
    p {
        margin: 0 auto 50px;
        font-size: 21px;
        max-width: 922px;
        line-height: 27px;
        @media (max-width: $breakpoint_tablet) {
            margin-bottom: 30px;
            font-size: 15px;
            line-height: 24px;
        }
    }
    .banner_btns {
        display: flex;
        align-items: center;
        gap: 25px;
        justify-content: center;
        a {
            display: inline-block;
            transition: all 0.4s ease-in-out;
            &:hover {
                transform: scale(0.98);
                box-shadow: 0 10px 40px rgba(0,0,0,0.2);
                border-radius: 12px;
            }
            @media (max-width: $breakpoint_tablet) {
                img { max-width: 100%;}
            }
        }
    }
}

.swiper-pagination-bullet {
    background-color: #fff !important;
    // opacity: 1 !important;
    width: 30px !important;
    border-radius: 3px !important;
    &.swiper-pagination-bullet-active {
        
    }
}