@import "../../../assets/styles/variables.scss";
.contact_info_wrap {
    margin-bottom: 100px;
    @media (max-width: $breakpoint_mobile_landscape) {
        margin-bottom: 40px;
    }
    .container {
        gap: 50px;
        align-items: center;
        @media (max-width: $breakpoint_mobile_landscape) {
            flex-direction: column;
        }
    }
    .contact_form {
        width: 50%;
        background: linear-gradient(0deg, rgba(1,56,183,1) 0%, rgba(52,182,235,1) 100%);
        border-radius: 15px;
        padding: 30px;
        @media (max-width: $breakpoint_mobile_landscape) {
            width: 100%;
        }
        h2 {
            margin: 0 0 30px;
            font-size: 42px;
            font-weight: bold;
            color: #fff;
            @media (max-width: $breakpoint_tablet) {
                font-size: 26px;
            }
        }
        ul {
            li {
                margin-bottom: 15px;
            }
        }
    }
    .contact_us_info {
        width: 50%;
        @media (max-width: $breakpoint_mobile_landscape) {
            width: 100%;
        }
        h2 {
            margin: 0 0 30px;
            font-size: 42px;
            font-weight: bold;
            color: #0036b8;
            @media (max-width: $breakpoint_tablet) {
                font-size: 26px;
            }
        }
        ul {
            li {
                font-size: 20px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                background-color: #fff;
                box-shadow: 0 0 10px rgba(52,182,235,0.2);
                padding: 15px 10px 15px 20px;
                border-left: 5px solid rgb(212,175,58);
                a,span {
                    color: #19191D;
                    display: flex;
                    align-items: flex-start;
                    i,img { width: 35px; margin-top: 2px; color: #0036b8;}
                    img {
                        height: 14px;
                        margin-top: 5px;
                        object-position: -7px;
                    }
                }
                a {
                    &:hover {
                        i { transform: scale(1.1);}
                    }
                }
            }
        }
    }
}